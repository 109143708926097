// Base
.page-header {
    border-bottom: 1px solid #eee;
}

// SVG Icon Styles
.icon {
    width: 18px;
    height: 18px;
    vertical-align: sub;
    fill: currentColor;
}

// Footer
.site-footer {
    border-top: 1px solid $gray-200;
    margin-top: 2rem;
    padding: 1rem 0;
}
  
.footer-item {
    margin: 0.5rem;
    white-space: nowrap;
}

// Card
.card-img:hover {
    opacity: 0.8;
    transition: all .2s ease-in-out;
}

h1 small {
    font-size: 65%;
    line-height: 1;
    color: #777;
}

// Carousel
.card-img {
    height: auto;
}
